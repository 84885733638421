import { useStage3SubmissionDocData, useStatDocData } from '../../../api/game';
import { stages } from '../../../lib/Problem';
import { calcRankMessage } from '../../../lib/stageResults';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import StageTitle from '../../../static/png/Stage_title.png';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CTResultScore,
  CommonBG,
  Mission,
  Overlay,
  MainButton as _Button,
  OverlayContent as _OverlayContent,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface ResultFinalProps {
  onBack?: () => void;
}

// FIXME: URL参照に変更

const ResultFinal: React.FC<ResultFinalProps> = props => {
  const [showOverlay, setShowOverlay] = React.useState(-1);
  const maxScore = stages.length;

  const { eventId } = useParams<'eventId'>();
  const historyUrl = 'https://specc-dev.riddler.co.jp/history/' + eventId;
  const uid = useCurrentUser()?.uid;
  const [submission, loadSubmission, submissionError] =
    useStage3SubmissionDocData(eventId, uid);
  const [stat, loadStat, statError] = useStatDocData(eventId);

  const navigate = useNavigate();
  const goTo = (url: string) => {
    navigate(url);
  };
  const rank = React.useMemo(
    () => calcRankMessage(submission?.totalScore ?? 0, 2),
    [submission?.totalScore]
  );
  const average = React.useMemo(() => {
    return stat?.averageScore ?? 0;
  }, [stat]);

  const params = useMemo(() => {
    return submission?.answers.map((single, i) => {
      return {
        score: single,
        rate: (stat?.rate?.[i] ?? 0) * 100,
      };
    });
  }, [submission, submission?.answers, stat]);

  return (
    <CommonBG
      withHeader
      withFooter
      headerOptions={{ buttonType: 'logout', logoType: 'CT' }}
    >
      <Overlay
        isVisible={showOverlay >= 0}
        onClick={() => {
          setShowOverlay(-1);
        }}
      >
        {showOverlay >= 0 && (
          <OverlayContent>
            <img src={stages[showOverlay].image} />
            <p>
              <b>?</b>
              {stages[showOverlay].title}
            </p>
          </OverlayContent>
        )}
      </Overlay>
      <Rule>
        <Title src={StageTitle} />
        <Mission>
          制限時間以内にできるだけ多くの
          <br />
          ナゾを解きなさい。
        </Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 8分
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <CTResultScore
          score={submission?.totalScore ?? 0}
          maxScore={maxScore}
          rank={rank}
          average={average}
        />
        <Description>
          <h2>あなたの解答</h2>
          <ListWrapper>
            <ListHeader>
              <p>問題</p>
              <p>解答タイム</p>
              <p>突破率</p>
            </ListHeader>
            {params?.map((single, i) => {
              return (
                <SingleList key={`res-${i}`}>
                  <div>
                    <StageNum>{i + 1}</StageNum>
                    <Example
                      onClick={() => {
                        setShowOverlay(i);
                      }}
                    >
                      <img src={stages[i].image} />
                    </Example>
                  </div>
                  <div>
                    {single?.score == null ? (
                      <>
                        {i == params.length - 1 ? (
                          <ResultSec style={{ opacity: 0.3 }}>
                            (未解答)
                          </ResultSec>
                        ) : (
                          <ResultSec style={{ opacity: 0.3 }}>
                            (スキップ)
                          </ResultSec>
                        )}
                      </>
                    ) : (
                      <>
                        <ResultSec style={{ fontSize: '3.0rem' }}>
                          <Correct />
                          {single.score}秒
                        </ResultSec>
                      </>
                    )}
                  </div>
                  <div>
                    {single?.rate != null && <Rate>{single.rate}%</Rate>}
                  </div>
                </SingleList>
              );
            })}
          </ListWrapper>
        </Description>
        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => {
              location.href = historyUrl;
            }}
          >
            戻る
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};
const Correct = () => {
  return (
    <svg
      id='Component_-_Complete'
      data-name='Component - Complete'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='パス_206'
        data-name='パス 206'
        d='M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z'
        fill='#05aa70'
      />
      <path
        id='Checkbox'
        d='M4.6,8,0,3.4l.933-.933L4.6,6.067,10.667,0,11.6.933Z'
        transform='translate(4.001 6)'
        fill='#fff'
      />
    </svg>
  );
};

const Rate = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

const OverlayContent = styled(_OverlayContent)`
  img {
    width: 600px !important;
  }

  p {
    width: 600px !important;
  }

  b {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    background: #d53220;
    padding: 2px 20px;
    margin-right: 10px;
  }
`;

const ResultSec = styled.div`
  flex: 0.9;
  font-weight: bold;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const Example = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;

  img {
    width: 200px;
    height: auto;
    margin: 10px;
    border: 1px solid #ced4da;
  }

  > div:last-child {
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
    align-items: center;

    p {
      margin: 10px;
    }
  }
`;
const StageNum = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  background: #343a40;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SingleList = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  &:nth-child(2n) {
    background: #ffffff;
  }

  &:nth-child(2n-1) {
    background: #f3f4f6;
  }

  >div: nth-child(1) {
    flex: 1;
    display: flex;
  }
  >div: nth-child(2) {
    flex: 0.9;
    padding-left: 20px;
  }
  >div: nth-child(3) {
    flex: 0.9;
  }
`;
const ListWrapper = styled.div`
  border: 1px solid #ced4da;
  overflow: hidden;
  transition: 0.2s;
`;

const ListHeader = styled.div`
  display: flex;
  background: #f3f4f6;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 12px;

  >p: nth-child(1) {
    flex: 1;
    padding-left: 50px;
  }
  >p: nth-child(2) {
    flex: 0.9;
    padding-left: 60px;
  }
  >p: nth-child(3) {
    flex: 0.9;
  }
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  max-width: 600px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  width: 100%;
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

export default ResultFinal;
