import { app, parentURI } from '../../..';
import {
  CommonBG,
  TextWindow,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import { getAuth, signOut } from 'firebase/auth';
import * as React from 'react';
import styled from 'styled-components';

type AuthErrorPageProps = Record<string, never>;
const AuthErrorPage: React.FC<AuthErrorPageProps> = () => {
  return (
    <CommonBG>
      <Wrapper>
        <Text>
          {
            '認証に失敗しました。下記ボタンを押下し、再度トップページからやり直してください。'
          }
        </Text>
        <Button
          color='positive'
          size='large'
          onClick={() => {
            signOut(getAuth(app)).then(() => {
              window.location.href = `${parentURI}/token-refresh`;
            });
          }}
        >
          再認証する
        </Button>
      </Wrapper>
    </CommonBG>
  );
};

export default AuthErrorPage;

const Wrapper = styled.div`
  position: relative;
  padding-top: 8rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;

const Button = styled(_Button)`
  display: block;
  margin: 3rem auto 4rem;
`;
