import {
  MainButton,
  CommonBG,
  TextWindow as _TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

export const Finish: React.FC = () => {
  const location = useLocation();
  const params = location.state as (number | null)[] | null;

  const navigate = useNavigate();
  const goTo = (url: string, params: any) => {
    navigate(url, { state: params });
  };
  const { eventId } = useParams<'eventId'>();

  return (
    <CommonBG>
      <Wrapper>
        <TextWindow bracket>
          <p>ステージ3は終了です。お疲れさまでした。</p>
          <MainButton
            color='positive'
            size='large'
            onClick={() => {
              goTo(`/events/${eventId}/result`, params);
            }}
          >
            OK
          </MainButton>
        </TextWindow>
      </Wrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWindow = styled(_TextWindow)`
  p {
    margin: 20px;
  }

  button {
    margin-bottom: 30px;
  }
`;
