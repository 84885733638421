import Question_1 from '../static/jpg/Q1.jpg';
import Question_2 from '../static/jpg/Q2.jpg';
import Question_3 from '../static/jpg/Q3.jpg';
import Question_4 from '../static/jpg/Q4.jpg';
import Question_5 from '../static/jpg/Q5.jpg';
import Question_6 from '../static/jpg/Q6.jpg';
import Question_7 from '../static/jpg/Q7.jpg';
import Question_8 from '../static/jpg/Q8.jpg';
import Question_9 from '../static/jpg/Q9.jpg';
import Question_10 from '../static/jpg/Q10.jpg';
import Question_11 from '../static/jpg/Q11.jpg';
import Question_12 from '../static/jpg/Q12.jpg';
import Question_13 from '../static/jpg/Q13.jpg';
import Question_14 from '../static/jpg/Q14.jpg';
import Question_15 from '../static/jpg/Q15.jpg';
import Question_16 from '../static/jpg/Q16.jpg';
import Question_17 from '../static/jpg/Q17.jpg';
import Question_18 from '../static/jpg/Q18.jpg';
import Question_19 from '../static/jpg/Q19.jpg';
import Question_20 from '../static/jpg/Q20.jpg';

// import Question_21 from '../static/jpg/Q21.jpg';
// import Question_22 from '../static/jpg/Q22.jpg';
// import Question_23 from '../static/jpg/23.jpg';

export interface SingleStage {
  title: string;
  hint: string[];
  answer: string[];
  answerText?: string;
  image: string;
}

export const stages: SingleStage[] = [
  /*{
    title: 'に入る2文字は？',
    hint: ['形容詞が３つ並ぶ', '反対の言葉が並ぶ', '右は「さむい」'],
    answer: ['あさ', 'アサ', '朝'],
        answerText: `【答え：あさ】
    矢印は「反対の意味」を表しています。
    「うすい」の対義語で「あつい」
    「あつい」の対義語で「さむい」が入ります。`,
    image: Question_1,
  },*/
  {
    title: 'に入る2文字は？',
    hint: ['４つだけあるもの', 'カードに関係する', 'トランプに関係する'],
    answer: ['くだ', 'クダ', '管'],
    answerText: `【答え：くだ】
    ４つのトランプのマークの、頭の2文字が縦書きで並んでいます。
    すぺーど→すぺ
    くらぶ →くら
    だいや →だい
    はーと →はーと`,
    image: Question_1,
  },
  {
    title: 'に入る食品は？',
    hint: [
      'イラストをひらがなに',
      '左から１、２、３……',
      '「いち」ご、「に」く……',
    ],
    answer: ['ごま', 'ゴマ', '胡麻'],
    answerText: `【答え：ごま】
    ひらがなの頭に「1・２・３・４・５」を付けると下のイラストになります。
    いち ご
    に く
    さん ご
    し か
    ご ま`,
    image: Question_2,
  },
  {
    title: 'に入る言葉は？',
    hint: [
      '縦に並ぶイラストを比べる',
      '左下：「ツリー」 右上：「から」',
      '上の行＋「ー」＝下の行',
    ],
    answer: ['からー', 'カラー'],
    answerText: `【答え：カラー】
    上のイラストの言葉に伸ばし棒をつけると下のイラストになります。
    つり\t→\tつりー
    かば\t→\tかばー
    から\t→\tからー`,
    image: Question_3,
  },
  {
    title: 'に入る2文字は？',
    hint: ['５つだけあるもの', '手に関係する', '指に関係する'],
    answer: ['ひな', 'ヒナ', '雛'],
    answerText: `【答え：ひな】
    手の指の名前の、頭の2文字が縦書きで並んでいます。
    おやゆび\t→おや
    ひとさしゆび\t→ひと
    なかゆび\t→なか
    くすりゆび\t→くす
    こゆび\t→こゆ`,
    image: Question_4,
  },
  {
    title: 'に入る2文字は？',
    hint: [
      '答えはカタカナになる',
      '縦に並ぶイラストを比べる',
      '上の行＋真ん中の行＝下の行',
    ],
    answer: ['つえ', 'ツエ', '杖'],
    answerText: ``,
    image: Question_5,
  },
  {
    title: 'に入る3文字は？',
    hint: ['横に並ぶ文字を比べる', '左から右に増える', '書き順'],
    answer: ['くない', 'クナイ', '宮内', '区内'],
    image: Question_6,
  },
  {
    title: 'に入る1文字は？',
    hint: ['頭文字', '色に関係する', '虹に関係する'],
    answer: ['み', 'ミ'],
    image: Question_7,
  },
  {
    title: 'に入る生き物は？',
    hint: [
      '横に並ぶイラストを比べる',
      '上の行は「た」「たか」「たから」',
      '下の行は「か」「？」「かいが」',
    ],
    answer: ['かい', 'カイ', '貝'],
    image: Question_8,
  },
  {
    title: 'に入る漢字1文字は？',
    hint: [
      '漢字の一部に注目',
      '隣同士を比べる',
      '右側と左側が交互に入れ替わる',
    ],
    answer: ['銀'],
    image: Question_9,
  },

  {
    title: 'に入る2文字は？',
    hint: ['横書きではない', '右上から縦読み', '時代に関係する'],
    answer: ['うし', 'ウシ', '牛'],
    image: Question_10,
  },
  {
    title: 'に入る2文字は？',
    hint: [
      '赤枠には「みみ」も入る',
      '青枠：植物に関係する',
      '赤枠：顔に関係する',
    ],
    answer: ['はな', 'ハナ'],
    image: Question_11,
  },
  {
    title: 'に入る3文字は？',
    hint: ['７つだけあるものの一部', '逆から読む', '１週間に関係する'],
    answer: ['どんき', 'ドンキ', '鈍器'],
    image: Question_12,
  },
  {
    title: 'に入る食べ物は？',
    hint: ['食べ物の名前', '頭文字', '英語で読む'],
    answer: [
      'ぐれーぷ',
      'グレープ',
      'Grape',
      'grape',
      'GRAPE',
      'grapes',
      'Grapes',
      'GRAPES',
      '葡萄',
      'ぶどう',
      'ブドウ',
    ],
    image: Question_13,
  },
  {
    title: 'に入る1文字は？',
    hint: ['頭文字', 'どんどん長くなる', '時に関係する'],
    answer: ['じ', 'ジ', '時'],
    image: Question_14,
  },
  {
    title: 'に入る海の生き物は？',
    hint: [
      '上の行と下の行は別',
      '上の行は「かい」「きつね」「ね」「つき」「いか」',
      '逆からも続けて読む',
    ],
    answer: ['えい', 'エイ', '鱏'],
    image: Question_15,
  },
  {
    title: 'に入る3文字は？',
    hint: [
      '「けー」「しー」「えむ」はアルファベット',
      'しー＝せ◯ち',
      '長さの単位に関係する',
    ],
    answer: ['ろんり', 'ロンリ', '論理'],
    image: Question_16,
  },
  {
    title: 'に入る食べ物は？',
    hint: [
      'イラストを２文字に',
      '左：つち　右：くき',
      '五十音表で「つち」と「くき」の間',
    ],
    answer: ['すし', 'スシ', '寿司'],
    image: Question_17,
  },
  {
    title: 'に入る言葉は？',
    hint: [
      '答えはカタカナになる',
      '３行のブロックで１かたまり',
      '真ん中の行が徐々に左に進んでいる',
    ],
    answer: ['こーと', 'コート'],
    image: Question_18,
  },
  {
    title: 'に入る言葉は？',
    hint: ['文字は縦書き', '数字に関係する', '右端は「SIX」'],
    answer: ['who', 'WHO', 'Who'],
    image: Question_19,
  },
  {
    title: 'に入る1文字は？',
    hint: ['縦１列で単語になる', 'アルファベットを使う', '１＝A'],
    answer: ['ん', 'ン'],
    answerText: `【答え：ん】
    １→A、２→Bのように、数字をアルファベットの順番と対応させて変換してみると、縦に読んで単語ができるようになっています。
    １：えーす
    ２：びーすと
    ３：しーるど
    ４：でぃーらー
    ５：いーすと
    ６：えふで
    ７：じーぱ？
    「じーぱ？」で言葉になるのは「じーぱん」のみです。`,
    image: Question_20,
  },
  /*
  {
    title: 'に入る言葉は？',
    hint: [
      '上の行は「たき」「たい」「たま」',
      '左の列は「たき」「かき」「えき」',
      '横行は１文字目、縦列は２文字目に注目',
    ],
    answer: ['えま', 'エマ', '絵馬'],
    image: Question_21,
  },
  {
    title: 'に入る2文字は？',
    hint: [
      '左側の文字サイズに注目',
      '文字サイズは「大」「中」「小」',
      '下の行は「しょうわ」',
    ],
    answer: ['さい', 'サイ'],
    image: Question_22,
  },*/
];
