import { stages } from '../../../lib/Problem';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import TimeGauge from '../../uiElements/TimeGauge';
import { faFastForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MainButton as _Button,
  CommonBG,
  TextWindow as _TextWindow,
  Input as _Input,
  SubButton,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

interface GameProps {}

const duration = 8 * 60;

const limit = (n: number, min: number, max: number) => {
  return Math.max(min, Math.min(n, max));
};

const wait = (ms: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
};

const Game: React.FC<GameProps> = () => {
  const { eventId } = useParams<'eventId'>();
  const uid = useCurrentUser()?.uid;
  const localRecordName = (name: string) => `${eventId}-${uid}-${name}`;
  const [currentStage, setCurrentStage] = useState<number>(
    localRecordName('currentStage'),
    0
  );
  const [currentSec, setCurrentSec] = useState<number>(
    localRecordName('currentSec'),
    duration
  );
  const [startSec, setStartSec] = useState<number>(
    localRecordName('startSec'),
    duration
  );
  const [inputStr, setInputStr] = useState<string>(
    localRecordName('inputStr'),
    ''
  );

  const [stopCount, setStopCount] = useState<boolean>(
    localRecordName('stopCount'),
    false
  );
  const [inAnim, setInAnim] = useState<boolean>(
    localRecordName('inAnim'),
    false
  );

  const [incorrect, setIncorrect] = useState<boolean>(
    localRecordName('incorrect'),
    false
  );
  const [correct, setCorrect] = useState<boolean>(
    localRecordName('correct'),
    false
  );

  const [inOutAnim, setInOutAnim] = useState<boolean>(
    localRecordName('inOutAnim'),
    false
  );

  const [correctTimes, setCorrectTimes] = useState<(number | null)[]>(
    localRecordName('correctTimes'),
    []
  );

  const navigate = useNavigate();
  const goTo = (url: string, params: any) => {
    navigate(url, { state: params });
  };

  // タイムアップ
  const onFinish = () => {
    goTo(`/events/${eventId}/finish`, [...correctTimes, null]);
    setCorrectTimes([...correctTimes, null]);
  };

  const skip = async () => {
    if (inAnim == true) return;

    setInAnim(true);
    setCorrectTimes([...correctTimes, null]);

    // フェードアウト
    setInOutAnim(true);

    await wait(600);

    // ステージを移動しフェードイン
    setStopCount(true);
    setCurrentStage(currentStage + 1);
    setInOutAnim(false);

    await wait(600);

    // フェード終了後に再開
    setInputStr('');
    setInAnim(false);
    setStartSec(currentSec - 1);
  };

  const send = async () => {
    if (inAnim == true) return;
    if (inputStr == '') return;

    if (stages[currentStage].answer.includes(inputStr.trim())) {
      setCorrect(true);
      setInAnim(true);
      setCorrectTimes([...correctTimes, startSec - currentSec]);

      await wait(1000);
      // フェードアウト
      setInOutAnim(true);

      await wait(600);

      // 全問終了
      if (currentStage >= stages.length - 1) {
        goTo(`/events/${eventId}/finish`, [
          ...correctTimes,
          startSec - currentSec,
        ]);
        return;
      }

      // ステージを移動しフェードイン
      setStopCount(true);
      setCurrentStage(currentStage + 1);
      setInOutAnim(false);

      await wait(600);

      // フェード終了後に再開
      setInputStr('');
      setCorrect(false);
      setInAnim(false);
      setStartSec(currentSec - 1);
    } else {
      setIncorrect(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
    send();
  };

  return (
    <CommonBG>
      <Wrapper>
        <TimeGauge
          duration={duration}
          timerKey={`ct2switch-${eventId}-${uid}`}
          onRefreshDuration={s => {
            setCurrentSec(s);

            if (stopCount && inAnim == false) {
              setStopCount(false);
            }
          }}
          onFinished={onFinish}
        />

        <TextWindow bracket>
          <b>?</b>
          {stages[currentStage].title}
        </TextWindow>

        <Problem style={{ opacity: inOutAnim ? 0 : 1 }}>
          <ProblemImage>
            <div>{currentStage + 1}</div>
            <img src={stages[currentStage].image} />
          </ProblemImage>
          <Hints>
            {stages[currentStage].hint.map((single, h) => {
              const open =
                stopCount == false && startSec - currentSec > 10 * (h + 1);
              const gauge = limit(
                ((10 * (h + 1) - (startSec - currentSec)) / 10) * 100,
                0,
                100
              );

              return (
                <li key={`hint-${h}`}>
                  <div>
                    <p>手がかり{h + 1}</p>
                    <span />
                    <span />
                  </div>
                  {open ? (
                    <div>
                      <FadeIn>{single}</FadeIn>
                    </div>
                  ) : (
                    <HintCover>
                      <span
                        style={{
                          transition: stopCount ? '0s liner' : '1s linear',
                          width: `${stopCount ? 100 : gauge}%`,
                        }}
                      ></span>
                      <p>{10 * (h + 1)}秒経過で解放されます</p>
                    </HintCover>
                  )}
                </li>
              );
            })}
            <Skip>
              <SubButton
                color='negative'
                size='medium'
                disabled={startSec - currentSec <= 40}
                onClick={() => {
                  skip();
                }}
              >
                40秒経過でスキップ
                <FontAwesomeIcon icon={faFastForward} />
              </SubButton>
            </Skip>
          </Hints>
        </Problem>

        <Answer>
          <Result>
            {incorrect && (
              <div>
                <Incorrect />
                <p style={{ color: '#EA0E49' }}>答えが間違っています。</p>
              </div>
            )}
            {correct && (
              <div>
                <Correct />
                <p style={{ color: '#05AA70' }}>お見事、正解です！</p>
              </div>
            )}
          </Result>
          <div>
            <Input
              error={incorrect}
              correct={correct}
              value={inputStr}
              placeholder='入力してください'
              disabled={false}
              onChange={(t: string) => {
                if (correct) return;
                setInputStr(t);
                setIncorrect(false);
              }}
              onKeyDown={handleKeyDown}
            />
            <_Button
              disabled={inputStr == ''}
              color='positive'
              size='large'
              onClick={() => send()}
            >
              送信
            </_Button>
          </div>
        </Answer>
      </Wrapper>
    </CommonBG>
  );
};

interface InputProps {
  error: boolean;
  correct: boolean;
}

const errAnim = keyframes`
  0% {
    transform: translateX(0px);    
  }
  10% {
    transform: translateX(-10px);    
  }
  30% {
    transform: translateX(10px);    
  }
  50% {
    transform: translateX(-5px);    
  }
  70% {
    transform: translateX(5px);    
  }
  100% {
    transform: translateX(0px);
  }
`;

const correctAnim = keyframes`
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1.0);
  }
`;

const Input = styled(_Input)`
  ${(p: InputProps) =>
    p.error
      ? css`
          animation: ${errAnim} 0.6s !important;
          border-color: #ea0e49 !important;
          background: #ffe3e3 !important;
        `
      : ''}

  ${(p: InputProps) =>
    p.correct
      ? css`
          animation: ${correctAnim} 0.6s !important;
          border-color: #05aa70 !important;
          background: #d3f9d8 !important;
        `
      : ''}
`;

const Result = styled.div`
  height: 20px;
  width: 460px;
  margin: 0 auto;

  > div {
    display: flex;

    svg {
      margin-right: 8px;
    }
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

const HintCover = styled.div`
  position: relative;
  background: #ced4da !important;

  p {
    position: relative;
    color: white;
    font-weight: bold;
    z-index: 2;
  }

  span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #b2b9bf;
  }
`;

const Skip = styled.div`
  margin-top: 30px;

  button {
    margin-left: auto;
    display: block;

    svg {
      margin-left: 10px;
    }
  }
`;

const Problem = styled.div`
  display: flex;
  justify-content: center;

  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 40px;

  transition: 0.4s ease-in;
`;

const ProblemImage = styled.div`
  position: relative;
  margin-right: 20px;
  flex: 1;

  > div {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #343a40;
    color: white;
    font-weight: bold;
    font-size: 2.6rem;
    width: 5.6rem;
    height: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 380px;
    width: auto;
    border: 1px solid #ced4da;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    user-drag: none;
  }

  @media screen and (max-width: 1000px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const Hints = styled.div`
  flex: 0.8;

  li {
    list-style: none;

    > div:first-child {
      background: #343a40;
      color: white;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 4px 10px;
      width: 100px;
      position: relative;

      span {
        display: block;
        width: 10px;
        height: 100%;
        background: #343a40;
        top: 0;
        right: -16px;
        position: absolute;
      }
      span:last-child {
        right: -32px;
      }
    }

    > div:last-child {
      position: relative;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 1.8rem;
      border: 1px solid #ced4da;
      text-align: center;
      font-weight: bold;
      background: white;
      overflow: hidden;
      padding: 16px 20px;
    }
  }
`;

const slidein = keyframes`
  from {
    transform: translateX(400px);
  }
  to {
    transform: translateX(0px);
  }
`;

const FadeIn = styled.div`
  animation: ${slidein} 0.3s ease-out;
`;

const Incorrect = () => {
  return (
    <svg
      id='Component_-_Error'
      data-name='Component - Error'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <circle
        id='楕円形_111'
        data-name='楕円形 111'
        cx='10'
        cy='10'
        r='10'
        fill='#ea0e49'
      />
      <path
        id='Icon_-_Close'
        data-name='Icon - Close'
        d='M5,5.909.909,10,0,9.091,4.091,5,0,.909.909,0,5,4.091,9.091,0,10,.909,5.909,5,10,9.091,9.091,10Z'
        transform='translate(5 5)'
        fill='#fff'
      />
    </svg>
  );
};

const Correct = () => {
  return (
    <svg
      id='Component_-_Complete'
      data-name='Component - Complete'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='パス_206'
        data-name='パス 206'
        d='M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z'
        fill='#05aa70'
      />
      <path
        id='Checkbox'
        d='M4.6,8,0,3.4l.933-.933L4.6,6.067,10.667,0,11.6.933Z'
        transform='translate(4.001 6)'
        fill='#fff'
      />
    </svg>
  );
};

const Answer = styled.div`
  > div:last-child {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2px;

    input {
      margin-top: 10px;
      height: 60px;
      font-size: 2.6rem;
      border-width: 3px;
      text-align: center;
    }

    button {
      margin-left: 20px;
      min-width: 120px !important;
    }
  }
`;

const TextWindow = styled(_TextWindow)`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 10px;

  b {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    background: #d53220;
    padding: 2px 30px;
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
  padding: 0 20px;
`;

/*
const Button = styled(_Button).attrs({
  variant: 'main',
})`
  display: block;
  margin: 0 auto;
  margin-top: 20rem;
`;
*/

export default Game;
