import { app } from '../..';
import Loading from './common/Loading';
import CountDown from './stagePages/CountDown';
import { Finish } from './stagePages/Finish';
import Game from './stagePages/Game';
import ResultFinal from './stagePages/ResultFinal';
import ResultPage from './stagePages/ResultPage';
import StartPage from './stagePages/StartPage';
import { useCTSignIn } from '@riddler-co-jp/specc-auth-client';
import * as React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const match = useMatch('/events/:eventId/*');
  const [signedIn, signInLoading] = useCTSignIn(
    app,
    'create',
    match?.params.eventId,
    '2',
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );

  if (signInLoading) {
    return <Loading />;
  }
  if (!signInLoading && !signedIn) {
    return <Navigate to='/auth-error' />;
  }

  return (
    <Routes>
      <Route path='/:eventId/' element={<StartPage />} />
      <Route path='/:eventId/countdown' element={<CountDown />} />
      <Route path='/:eventId/game' element={<Game />} />
      <Route path='/:eventId/finish' element={<Finish />} />
      <Route path='/:eventId/result' element={<ResultPage />} />
      <Route path='/:eventId/result/create' element={<ResultFinal />} />
    </Routes>
  );
};

export default EventsRouter;
